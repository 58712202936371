<template>
    <radius-loader v-if="loading" />
    <section
        v-else
        class="wrapper wrapper--xs"
    >
        <div class="my-orders-details">
            <div>
                <order-card
                    :key="order.orderid"
                    mode="solo"
                    :order="order"
                    :unread-messages-count="order.counters.unread_messages_count"
                    :unread-ticket-messages-count="order.counters.unread_ticket_messages_count"
                    :unread-files-main-count="order.counters.unread_files_main_count"
                    :unread-files-add-count="order.counters.unread_files_add_count"
                    @show:modal="showModal"
                />

                <!-- Order information Tabs -->
                <section
                    ref="tabs"
                    class="orders-details-tabs"
                >
                    <order-details-tabs
                        :items="getTabs"
                        @redirectToTab="redirectToTab"
                    />
                    <select-tabs
                        v-model="selectedTab.value"
                        :field.sync="selectedTab.value"
                        :value-select="selectedTab.value"
                        :items="getTabs"
                        name="Tabs"
                    />
                    <router-view
                        v-if="watchOrderid && !loading"
                        :key="$route.fullPath"
                        :order="order"
                        @fileUploaded="filesAddCounter += 1"
                        @deleteFile="filesAddCounter -= 1"
                        @actionAddMessage="messageLength += 1"
                        @supportFormCreated="onSupportFormCreated"
                        @markAsRead="markAsReadCounter('messages')"
                        @markAsReadMain="markAsReadCounter('files_main')"
                        @markAsReadAdditional="markAsReadCounter('files_add')"
                        @supporMarkAsRead="markAsReadCounterTickets('tickets', $event)"
                    />
                </section>
            </div>
        </div>

        <component
            :is="modal.modal"
            v-if="showPopup"
            :data="modal.data"
            :order="order"
            @confirmModal="confirmModal"
            @closeModal="closeModal"
        />
    </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { eventBus } from '@/helpers/event-bus'
import Api from '@/helpers/api/index.js'

// Components
import OrderCard from '@/components/account/cards/order-card/OrderCard.vue'
import OrderDetailsTabs from '@/components/account/cards/order-card/OrderDetailsTabs.vue';
import SelectTabs from '@/components/common/SelectTabs.vue';
import RadiusLoader from '@/components/common/RadiusLoader'

// Order Modals
import ConfirmModal from '@/components/account/orders/modals/ConfirmModal';
import FeedbackModal from '@/components/account/orders/modals/FeedbackModal';
import DeadlineModal from '@/components/account/orders/modals/DeadlineModal';
import ExtraPagesModal from '@/components/account/orders/modals/ExtraPagesModal';
import RevisionModal from '@/components/account/orders/modals/RevisionModal';

// Vuex
import {
    GET_PRICING
} from '@/store/modules/config/action-types'
import {
    RESET,
    SET_ORDER_DETAILS_BREADCRUMBS
} from '@/store/modules/order/mutation-types'

const {
    mapMutations: mapOrderMutations
} = createNamespacedHelpers('order')
const {
    mapActions: mapConfigActions
} = createNamespacedHelpers('config')

export default {
    components: {
        OrderCard,
        OrderDetailsTabs,
        SelectTabs,
        RadiusLoader,
        // modals
        ConfirmModal,
        FeedbackModal,
        DeadlineModal,
        ExtraPagesModal,
        RevisionModal
    },
    props: {
        orderProps: {
            type: Object,
            required: true
        }
    },
    metaInfo() {
        return {
            title: `${this.orderid} | Order Details`
        }
    },
    data: () => ({
        loading: true,
        order: {},
        ticketLength: 0,
        ticketUnread: 0,
        messageLength: 0,
        messagesUnread: 0,
        // files
        filesMainCounter: 0,
        filesAddCounter: 0,
        filesMainUnreadCounter: 0,
        filesAddUnreadCounter: 0,
        showCreateTicketForm: false,
        watchOrderid: true,
        // modals
        showPopup: false,
        modal: {},
        counterUnread: 0,
        selectedTab: {
            name: 'order_details',
            text: 'Details'
        }
    }),
    computed: {
        orderid() {
            return this.$route.params.id
        },
        isExpressOrder() {
            return this.order.flow_type === 2
        },
        getTabs() {
            return [
                {
                    value: 'order_details',
                    icon: 'list',
                    text: 'Details',
                    counter: false
                },
                {
                    value: 'order_standard_files',
                    icon: 'PaperClip',
                    text: 'Files',
                    counter: this.filesMainCounter,
                    counterUndread: this.filesMainUnreadCounter,
                    hide: this.isExpressOrder
                },
                {
                    value: 'order_standard_additional_files',
                    icon: 'PaperClip',
                    text: 'Additional Files',
                    counter: this.filesAddCounter,
                    counterUndread: this.filesAddUnreadCounter
                },
                {
                    value: 'order_standard_messages',
                    icon: 'Mail',
                    text: 'Messages',
                    counter: this.messageLength,
                    counterUndread: this.messagesUnread,
                    hide: this.isExpressOrder
                },
                {
                    value: 'order_standard_support',
                    icon: 'Support',
                    text: 'Help Center',
                    counter: this.ticketLength,
                    counterUndread: this.ticketUnread,
                    hide: this.isExpressOrder
                }
            ]
        }
    },
    watch: {
        async orderid() {
            if (this.watchOrderid) {
                await this.getOrderData()
            }
        },
        selectedTab: {
            handler() {
                this.redirectToTab(this.selectedTab.value)
            },
            deep: true
        }
    },
    async created() {
        this.watchOrderid = true
        await this.getOrderData()
        this.$socket.emit('order:subscribe', this.$route.params.id)
    },
    async activated() {
        this.watchOrderid = true
        await this.getOrderData()
    },
    deactivated() {
        this.watchOrderid = false
        this.$socket.emit('order:unsubscribe', this.orderProps.orderid)
    },
    beforeDestroy() {
        this.$socket.emit('order:unsubscribe', this.orderProps.orderid)
    },
    methods: {
        ...mapOrderMutations({
            resetOrder: RESET,
            orderDetailsInfo: SET_ORDER_DETAILS_BREADCRUMBS
        }),
        ...mapConfigActions([
            GET_PRICING
        ]),
        redirectToTab(selectTab) {
            this.$router.push({ name: selectTab, a: 'a' })
        },
        async getOrderData() {
            this.loading = true
            try {
                if (!this.orderProps || Object.keys(this.orderProps).length === 0) {
                    const { data } = await Api.get('/api/order/fetch', { orderid: this.$route.params.id })
                    this.order = data
                } else {
                    this.order = { ...this.orderProps }
                }
                this.orderDetailsInfo({
                    titleProject: this.order.project_information.title,
                    typeProject: this.order.project_information.type
                })
                // Counters
                const { counters } = this.order
                // message
                this.messageLength = counters.messages_count
                this.messagesUnread = counters.unread_messages_count
                // ticket
                this.ticketLength = counters.tickets_count
                this.ticketUnread = counters.unread_ticket_messages_count
                // files
                this.filesMainCounter = counters.files_main_count
                this.filesAddCounter = counters.files_add_count

                this.filesMainUnreadCounter = counters.unread_files_main_count
                this.filesAddUnreadCounter = counters.unread_files_add_count
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loading = false
            }
        },
        // MODALS
        showModal(payload) {
            this.showPopup = true
            this.modal = payload
        },
        closeModal() {
            this.showPopup = false
            this.modal = {}
        },
        async deleteUnpaidOrder(id) {
            try {
                await Api.delete('/api/order/action/delete', { id })
                eventBus.$emit('showSnackBar', `Order ${id} has been deleted!`, 'success');
                this.$router.push({ name: 'orders' })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async feedbackOrder(payload, isEdit) {
            try {
                if (isEdit) {
                    await Api.put('/api/testimonial/auth/update', payload)
                } else {
                    await Api.post('/api/testimonial/auth/create', payload)
                }
                this.order = {
                    ...this.order,
                    testimonials: [
                        {
                            ...this.order.testimonials[0],
                            ...payload,
                            nickname: this.order.writer.nickname,
                            sw_id: this.order.sw_id
                        }
                    ]
                }
                this.$router.push({ name: 'successful_rate' })
                // eventBus.$emit('showSnackBar', 'Your feedback has been submited. Thank you!', 'success')
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            }
        },
        async revisionOrder(payload) {
            try {
                await Api.post('/api/order-message/auth/create-revision', payload)
                this.order = {
                    ...this.order,
                    status: 'REVISION'
                }
                eventBus.$emit('showSnackBar', 'Your revision request has been submitted. Please wait for the status update by email', 'success')
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            }
        },
        async deadlineOrder(payload) {
            try {
                await Api.put('/api/order/action/extend-deadline', payload)
                this.order = {
                    ...this.order,
                    due_at: payload.datetime
                }
                eventBus.$emit('showSnackBar', 'The deadline has been extended.', 'success');
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            }
        },
        async confirmModal(payload, isEdit) {
            switch (this.modal.action) {
            case 'delete':
                await this.deleteUnpaidOrder(this.modal.orderid)
                break
            case 'feedback':
                await this.feedbackOrder(payload, isEdit)
                break
            case 'revision':
                await this.revisionOrder(payload)
                break
            case 'deadline':
                await this.deadlineOrder(payload)
                break
            default:
            }
            this.closeModal()
        },
        markAsReadCounter(type) {
            if (type === 'messages') {
                this.messagesUnread = 0
            } else if (type === 'files_main') {
                this.filesMainUnreadCounter = -1
            } else {
                this.filesAddUnreadCounter = -1
            }
            this.order[type].forEach((item) => {
                if (item.status_client === 0) {
                    item.status_client = 1
                }
            })
        },
        markAsReadCounterTickets(type, event) {
            this.counterUnread = this.ticketUnread
            this.order.tickets.forEach((item) => {
                if (item.ticketid === event.ticketid && item.unread_messages_count > 0) {
                    item.messages.forEach((message) => {
                        if (message.status_client === 0) {
                            message.status_client = 1
                        }
                    })
                    this.ticketUnread = this.counterUnread - item.unread_messages_count
                    item.unread_messages_count = 0
                }
            })
        },
        async onSupportFormCreated(payload) {
            this.ticketLength += 1
            this.order.tickets.push(payload)
        }
    }
}
</script>

<style lang="scss" scoped>
.order .tab-wrapper .tab {
    display: inline;
}

.create-support-ticket {
    transform: translateY(-150%);
    animation: ani 1s forwards;
}

@keyframes ani {
    0% {transform: translateY(-10%);}
    100% {transform: translateY(0);}
}
</style>
