<template>
    <radius-loader v-if="isLoading" />
    <component
        :is="component"
        v-else
        :order-props="order"
        @update:status="onUpdateStatus"
    />
</template>

<script>
import RadiusLoader from '@/components/common/RadiusLoader'
import { eventBus } from '@/helpers/event-bus'
import Api from '@/helpers/api/index.js'
import Express from '@/views/account/order/order-details/Express.vue'
import Standard from '@/views/account/order/order-details/Standard.vue'

export default {
    components: {
        RadiusLoader,
        Express,
        Standard
    },
    data() {
        return {
            isLoading: true,
            order: null
        }
    },
    computed: {
        component() {
            return this.order.status === 'INQUIRY' ? Express : Standard
        }
    },
    async activated() {
        await this.getOrderData()
    },
    methods: {
        async getOrderData() {
            this.isLoading = true
            try {
                const { data } = await Api.get('/api/order/fetch', { orderid: this.$route.params.id })
                this.order = data
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isLoading = false
            }
        },
        onUpdateStatus(payload) {
            this.order = {
                ...this.order,
                ...payload
            }
        }
    }
}
</script>
